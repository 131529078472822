import { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SORT } from '../../constants';
import PermissionWrapper from '../permissionWrapper';

function SortableTable({ columns: propsColumns, rows: propsRows, translationPath, teamId, currentlySelectedTeamId }) {
    const { t } = useTranslation();
    const [rows, setRows] = useState(propsRows);
    const [columns, setColumns] = useState(propsColumns.map(column => (column?.sortable ? { ...column, sort: SORT.ASC } : column)));
    const [selectedTeam, setSelectedTeam] = useState(currentlySelectedTeamId);

    useEffect(() => {
        setRows(propsRows);
    }, [propsRows]);

    const sortByColumn = (clickedColumn) => {
        setRows(prevState => [...prevState].sort((a, b) => {
            const aValue = a[clickedColumn.key]?.toString().toLowerCase() || '';
            const bValue = b[clickedColumn.key]?.toString().toLowerCase() || '';

            if (clickedColumn.sort === SORT.ASC) {
                if (clickedColumn?.type === 'date') {
                    return new Date(a[clickedColumn.key] || 0) - new Date(b[clickedColumn.key] || 0);
                }
                return aValue.localeCompare(bValue);
            }

            if (clickedColumn?.type === 'date') {
                return new Date(b[clickedColumn.key] || 0) - new Date(a[clickedColumn.key] || 0);
            }

            return bValue.localeCompare(aValue);
        }));

        setColumns(prevState => prevState.map((column) => {
            if (column.key === clickedColumn.key) {
                return { ...column, sort: column.sort === SORT.ASC ? SORT.DESC : SORT.ASC };
            }
            return column?.sort ? { ...column, sort: SORT.ASC } : column;
        }));
    };

    return (
        <Table bordered hover size="lg" className="sortableTable mt-2 mb-5">
            <thead className="table-header">
                <tr>
                    {columns?.map((column) => (
                        <PermissionWrapper
                            key={column.key}
                            allowed={column?.restrictedAccess && [column.restrictedAccess]}
                            teamId={teamId}
                        >
                            <th className={column?.icon ? 'text-center' : ''}>
                                <span className="d-flex justify-content-between align-items-center">
                                    <span>{t(`${translationPath}.${column.key}`)}</span>
                                    {column?.sort && (
                                        <i
                                            className={column.sort === SORT.ASC ?
                                                'bi bi-chevron-down sort-icon' : 'bi bi-chevron-up sort-icon'}
                                            onClick={() => sortByColumn(column)}
                                        />
                                    )}
                                    {column?.headerSuffix && <span>{column?.headerSuffix}</span>}
                                </span>
                            </th>
                        </PermissionWrapper>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows?.map((row, i) => (
                    <tr key={i}>
                        {columns?.map(column => (
                            <PermissionWrapper
                                key={`${i}-${column.key}`}
                                allowed={column?.restrictedAccess && [column.restrictedAccess]}
                                teamId={teamId}
                            >
                                <td className={column?.icon ? 'text-center' : ''}>
                                    {column?.icon && !column?.onClick && (
                                        <i className={`${column?.icon} m-2`}/>
                                    )}
                                    {column?.icon && column?.onClick && (
                                        <i
                                            className={`${column?.icon} m-2 cursor visible-icon`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                column.onClick(row, e);
                                            }}
                                        />
                                    )}
                                    {column?.link && (
                                        <Link
                                            className="link shadow-none"
                                            to={`${column.link}?teamId=${row?._id}`}
                                        >
                                            <h6>{row?.name}</h6>
                                        </Link>
                                    )}
                                    {!column?.icon && !column?.link && column.key !== 'switch_team' && (
                                        <span>
                                            {column?.formatDisplay ? column?.formatDisplay(row[column.key]) : row[column.key]}
                                        </span>
                                    )}
                                    {column.key === 'switch_team' && (
                                        <div className="d-flex justify-content-center">
                                            <input
                                                type="radio"
                                                name="selected_team"
                                                checked={selectedTeam === row._id}
                                                onChange={() => {
                                                    setSelectedTeam(row._id);
                                                    column.onClick(row);
                                                }}
                                                className="radio"
                                            />
                                        </div>
                                    )}
                                    {row[column?.suffixKey] && <span>{column?.suffix}</span>}
                                </td>
                            </PermissionWrapper>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default SortableTable;