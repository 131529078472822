import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AREA_OF_IMPROVEMENT_STATUSES, MODULE_TYPES, ROLES_PERMISSIONS } from '../../../../../constants';
import { editTaskToggle } from '../../../../../features/actionsSlice';
import { showToastErrorMessage } from '../../../../../features/toastSlice';
import { axios } from '../../../../../helpers/apiHelper';
import PermissionWrapper from '../../../../permissionWrapper';
import TeamsBestContribution from './TeamsBestContribution';

function MyActions() {
    const [actionPlansInAgenda, setActionPlansInAgenda] = useState();
    const [usersTasks, setUsersTasks] = useState();
    const { teamBestContributions } = useSelector((state) => state.actions);
    const { sections, userData: loggedUser } = useSelector((state) => state.agenda);
    const teamId = useSelector((state) => state.agenda?.userData?.lastSelectedTeam?._id);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const indexOfNotStarted = AREA_OF_IMPROVEMENT_STATUSES.indexOf('not-started');

    const fetchLoggedInUsersTasks = async () => {
        try {
            if (teamId && actionPlansInAgenda?.length > 0) {
                const tasks = await axios.get(`/task/loggedUser?modules=${JSON.stringify(actionPlansInAgenda?.map(ap => ap._id))}`);
                setUsersTasks(tasks?.data);
            } else {
                setUsersTasks([]);
            }
        } catch (err) {
            dispatch(showToastErrorMessage('error_message.dashboard.failed_loading_data'));
        }
    };

    useEffect(() => {
        if (sections) {
            const actionPlansInAgendaTemp = [];
            if (loggedUser?.teams?.filter(({ _id }) => _id === loggedUser?.lastSelectedTeam?._id)?.length !== 0) {
                sections?.map((section, sectionIndex) => section?.modules?.forEach((module, moduleIndex) => {
                    if (module.type === MODULE_TYPES.ACTIONS) {
                        actionPlansInAgendaTemp.push({ ...module, moduleIndex, sectionIndex });
                    }
                }));
            }
            setActionPlansInAgenda(actionPlansInAgendaTemp);
        }
    }, [sections, teamBestContributions]);

    useEffect(() => {
        if (teamId && actionPlansInAgenda) {
            (async () => {
                await fetchLoggedInUsersTasks();
            })();
        }
    }, [actionPlansInAgenda]);

    const convertDate = (timestamp) => {
        if (!timestamp) {
            return '';
        }

        const date = new Date(timestamp);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    };

    return (
        <>
            <div className="my-actions-container pt-2 px-3 m-1 d-flex  flex-column justify-content-between">
                <div>
                    <h4 className="sticky-lg-top lead" style={{ zIndex: 200 }}><strong>{t('content.teams_best_contributions')}</strong></h4>
                    <div className="my-actions">
                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.BEST_CONTRIBUTION_READ]}>
                            {actionPlansInAgenda?.length === 1 ? teamBestContributions?.map(bestContribution => (
                                <Container key={bestContribution?._id} className="mb-2">
                                    <TeamsBestContribution bestContribution={bestContribution}/>
                                </Container>
                            )) : actionPlansInAgenda?.map(actionPlanModule => (
                                <div key={actionPlanModule?._id} className="mb-2">
                                    <h6 className="pb-2 border-bottom">{actionPlanModule?.title}</h6>
                                    {teamBestContributions?.map(bestContribution => bestContribution?.module === actionPlanModule._id && (
                                        <TeamsBestContribution key={bestContribution?._id} bestContribution={bestContribution}/>
                                    ))}
                                </div>
                            ))}
                            {!teamBestContributions?.length &&
                                <Container><p className="mt-3">{t('content.no_best_contributions')}</p></Container>
                            }
                        </PermissionWrapper>
                    </div>
                </div>
                <div className="mt-1 ms-2">
                    {actionPlansInAgenda?.map(actionPlan => (
                        <Button
                            key={actionPlan?._id}
                            variant="link"
                            className="link shadow-none"
                            onClick={() => navigate(`/module/#_${actionPlan.sectionIndex}_${actionPlan.moduleIndex}`)}
                        >
                            <p>{`${t('content.go_to')} ${actionPlan.title}`}</p>
                        </Button>
                    ))
                    }
                </div>
            </div>
            <div className="my-actions-container pt-2 px-3 m-1 d-flex  flex-column justify-content-between">
                <div>
                    <h4 className="sticky-lg-top lead" style={{ zIndex: 200 }}><strong>{t('content.my_actions')}</strong></h4>
                    <Container className="my-actions">
                        <Row>
                            <Col>
                                <p className="pb-2 border-bottom">{t('content.upcoming_14_days')}</p>
                            </Col>
                        </Row>
                        {usersTasks?.map(task => {
                            const isResponsible = task?.responsible === loggedUser?._id;
                            const isSupportBy = task?.supportBy === loggedUser?._id || task?.fullTeam;
                            const taskDeadlinePassed = new Date(task?.deadline) < new Date();
                            return (
                                <Row key={task?._id} className="justify-content-between align-items-center mb-1">
                                    <Col xs={5}>
                                        <span className="cursor" onClick={() => dispatch(editTaskToggle({ isOpened: true, task }))}>
                                            {task?.title}
                                            {taskDeadlinePassed ? <span className="late-task">*</span> : ''}
                                        </span>
                                    </Col>
                                    <Col className={`${taskDeadlinePassed ? 'late-task' : ''} text-center`}>
                                        {convertDate(task?.deadline)}
                                    </Col>
                                    <Col className="text-center d-none d-md-block">
                                        {`${t(isResponsible ? 'content.responsible_shortcut' : '')}
                                        ${isResponsible && isSupportBy ? ' & ' : ''}
                                        ${t(isSupportBy ? 'content.support_by_shortcut' : '')}`}
                                    </Col>
                                    <Col xs={1}>
                                        <i className={`bi bi-circle-fill ms-2 me-2 dot
                                        ${AREA_OF_IMPROVEMENT_STATUSES[task?.status] || AREA_OF_IMPROVEMENT_STATUSES[indexOfNotStarted]}
                                    `}
                                        />
                                    </Col>
                                </Row>
                            );
                        })}
                        {!usersTasks?.length && <p className="mt-3 ms-2">{t('best_contribution.no_tasks')}</p>}
                    </Container>
                </div>
                <div className="mt-1 ms-2">
                    {actionPlansInAgenda?.map(actionPlan => (
                        <Button
                            key={actionPlan?._id}
                            variant="link"
                            className="link shadow-none"
                            onClick={() => navigate(`/module/#_${actionPlan.sectionIndex}_${actionPlan.moduleIndex}`)}
                        >
                            <p>{`${t('content.go_to')} ${actionPlan.title}`}</p>
                        </Button>
                    ))
                    }
                </div>
            </div>
        </>
    );
}

export default MyActions;