import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';

import { reformatToISODateString } from '../../../../helpers/generalHelpers';

function NewMeeting({
    closeModal,
    createMeeting,
    bestContributionsInModule,
    teamBestContributionsById,
    chartLabels,
    previousMeetings
}) {
    const { t } = useTranslation();
    const [lastMeetingMeasurementsByBestContributionIds, setLastMeetingMeasurementsByBestContributionIds] = useState();
    const measurmentValidationSchema = Yup.object().shape({
        bestContribution: Yup.string().required(t('error_message.required_field')),
        previousState: Yup.number().typeError(t('error_message.number_field')).required(t('error_message.required_field')),
        currentState: Yup.number().typeError(t('error_message.number_field')).required(t('error_message.required_field')),
        endGoal: Yup.number().typeError(t('error_message.number_field'))
    });
    const meetingValidationSchema = Yup.object().shape({
        meetingDate: Yup.date().typeError(t('error_message.date_field')).required(t('error_message.required_field')).test(
            'meeting_on_the_selected_date_already_exists',
            t('error_message.content.meeting_already_exists'),
            (value) => !chartLabels.includes(reformatToISODateString(new Date(value)))
        ),
        measurements: Yup.array().of(measurmentValidationSchema)
    });
    const {
        handleSubmit,
        register,
        control,
        setValue,
        formState: { errors }
    } = useForm({ resolver: yupResolver(meetingValidationSchema) });

    const updateLastMeeting = (date) => {
        if (previousMeetings?.length) {
            const meetingPriorToSelectedDate = previousMeetings?.find(meeting => new Date(meeting?.meetingDate) < date) || {};
            const meetingPriorToSelectedDateMeasurmentsWithoutDeleted = meetingPriorToSelectedDate?.measurements
                ?.filter((measurement) => Object.keys(teamBestContributionsById).includes(measurement.bestContribution));
            const meetingPriorToSelectedDateMeasurments = meetingPriorToSelectedDateMeasurmentsWithoutDeleted
                ?.reduce((acc, measurement, i) => {
                    setValue(`measurements[${i}].previousState`, measurement.currentState);
                    setValue(`measurements[${i}].currentState`, measurement.currentState);
                    return ({
                        ...acc,
                        [measurement.bestContribution]: measurement
                    });
                }, {});

            // if there are no previous inputs
            if (!Object.keys(meetingPriorToSelectedDate)?.length) {
                bestContributionsInModule?.forEach((bestContribution, i) => {
                    setValue(`measurements[${i}].previousState`, 0);
                    setValue(`measurements[${i}].currentState`, 0);
                });
            }
            setLastMeetingMeasurementsByBestContributionIds(meetingPriorToSelectedDateMeasurments || {});
        } else {
            setLastMeetingMeasurementsByBestContributionIds({});
        }
    };

    useEffect(() => {
        updateLastMeeting(new Date());
    }, []);

    return (
        <Modal show centered size="lg">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100 my-2">
                    <span>{t('content.create_new_meeting')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(createMeeting)}
                    className="container"
                >
                    <Row className="align-items-center mb-4">
                        <Col xs="auto" className="align-self-end"><Form.Label>{t('content.select_meeting_date')}</Form.Label></Col>
                        <Col xs="auto">
                            <Controller
                                name="meetingDate"
                                control={control}
                                error={errors.meetingDate?.message}
                                defaultValue={new Date()}
                                render={({ field }) => (
                                    <DatePicker
                                        className={`datepicker ${errors.meetingDate?.message && 'error-border'}`}
                                        placeholderText={t('content.select_meeting_date')}
                                        dateFormat="yyyy-MM-dd"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            updateLastMeeting(e);
                                        }}
                                        selected={field.value}
                                    />
                                )}
                            />
                        </Col>
                        {errors.meetingDate?.message && (
                            <Col className="align-self-end">
                                <Form.Label className="error-placeholder">
                                    {errors.meetingDate?.message}
                                </Form.Label>
                            </Col>
                        )}
                    </Row>
                    <Row className="my-1">
                        <Col><Form.Label>{t('best_contribution.best_contribution')}</Form.Label></Col>
                        <Col><Form.Label>{t('content.end_goal')}</Form.Label></Col>
                        <Col><Form.Label>{t('content.previous_state')}</Form.Label></Col>
                        <Col><Form.Label>{t('content.current_state')}</Form.Label></Col>
                    </Row>
                    {lastMeetingMeasurementsByBestContributionIds && bestContributionsInModule?.map(
                        (bestContribution, i) => (
                            <div key={bestContribution?._id}>
                                <Row className="justify-content-between align-items-start mb-2">
                                    <Col className="align-self-end">
                                        <Form.Label
                                            {...register(`measurements[${i}].bestContribution`, { value: bestContribution._id })}
                                        >
                                            <span>
                                                {teamBestContributionsById[bestContribution?._id]?.title ||
                                                    t('content.best_contribution_deleted')
                                                }
                                            </span>
                                        </Form.Label>
                                    </Col>
                                    <Col>
                                        <Row className="align-items-end">
                                            <Col>
                                                <Form.Control
                                                    className="removed-shadow"
                                                    name={`measurements[${i}].endGoal`}
                                                    disabled
                                                    {...register(`measurements[${i}].endGoal`, { value: bestContribution?.endGoal })}
                                                    defaultValue={bestContribution?.endGoal}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            className="removed-shadow"
                                            type="number"
                                            name={`measurements[${i}].previousState`}
                                            {...register(`measurements[${i}].previousState`, {
                                                value: (
                                                    lastMeetingMeasurementsByBestContributionIds[bestContribution?._id]?.currentState) || 0
                                            })}
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            className="removed-shadow"
                                            type="number"
                                            step={0.01}
                                            precision={2}
                                            name={`measurements[${i}].currentState`}
                                            {...register(`measurements[${i}].currentState`, {
                                                value: (
                                                    lastMeetingMeasurementsByBestContributionIds[bestContribution?._id]?.currentState
                                                ) || 0,
                                                valueAsNumber: true
                                            })}
                                            isInvalid={errors.measurements && errors.measurements[i]?.currentState?.message}
                                        />
                                    </Col>
                                </Row>
                                {errors.measurements && errors.measurements[i]?.currentState?.message && (
                                    <Row>
                                        <Col xs={{ offset: 9, span: 3 }}>
                                            <Form.Label className="error-placeholder">
                                                {errors.measurements[i]?.currentState?.message}
                                            </Form.Label>
                                        </Col>

                                    </Row>
                                )}

                            </div>
                        )
                    )}
                    <div className="d-flex mt-5 justify-content-between">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button type="submit" className="primary-button sign-button" style={{ width: '65%' }}>
                            {t('content.save')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default NewMeeting;