import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { store } from './store';
import SignIn from './components/signIn';
import SignUp from './components/signUp';
import Dashboard from './components/dashboard';
import UsersAdministration from './components/administration/users/usersAdministration';
import TeamsAdministration from './components/administration/teams/teamsAdministration';
import CompaniesAdministration from './components/administration/companies/companiesAdministration';
import { NavigationOutlet } from './outlets';
import PageNotFound from './components/pageNotFound';
import AddIdeaOrActionLayout from './layouts/addIdeaOrActionLayout';
import ForgotPassword from './components/forgotPassword';
import LayoutWithToastMessages from './layouts/toastMessagesLayout';
import ChangePassword from './components/changePassword';
import FirstLoginPasswordChange from './components/firstLoginPasswordChange';
import { shadeColor } from './helpers/cssHelper';
import { COLORS } from './constants';

import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/style/main.scss';

function App() {
    const { ready } = useTranslation(undefined, { useSuspense: false });

    useEffect(() => {
        // setting primary colors
        const r = document.querySelector(':root');
        r.style.setProperty('--primary-rgb', COLORS.PRIMARY_COLOR);
        r.style.setProperty('--secondary-rgb', shadeColor(COLORS.PRIMARY_COLOR, -20));
    }, []);

    return (
        <Provider store={store}>
            <BrowserRouter>
                {ready &&
                    <Routes>
                        <Route element={<LayoutWithToastMessages/>}>
                            <Route element={<NavigationOutlet/>}>
                                <Route element={<AddIdeaOrActionLayout/>}>
                                    <Route path="/" element={<Dashboard/>}/>
                                    <Route path="module/*" element={<Dashboard/>}/>
                                </Route>
                                <Route path="administration" element={<Outlet/>}>
                                    <Route path="users" element={<UsersAdministration/>}/>
                                    <Route path="teams" element={<TeamsAdministration/>}/>
                                    <Route path="companies" element={<CompaniesAdministration/>}/>
                                </Route>
                            </Route>
                            <Route path="sign-up/:id" element={<SignUp/>}/>
                            <Route exact path="forgot-password" element={<ForgotPassword/>}/>
                            <Route path="forgot-password/:guid" element={<ChangePassword/>}/>
                            <Route path="first-login-password-change" element={<FirstLoginPasswordChange/>}/>
                            <Route path="sign-in" element={<SignIn/>}/>
                            <Route path="*" element={<PageNotFound/>}/>
                        </Route>
                    </Routes>
                }
            </BrowserRouter>
        </Provider>
    );
}

export default App;