import { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import { REGEX } from '../../../constants';
import { axios } from '../../../helpers/apiHelper';
import { showToastErrorMessage, showToastSuccessMessage } from '../../../features/toastSlice';

function RegisterUsers({ closeModal, teamToRegisterTo, onUserAdded }) {
    const [isLoading, setIsLoading] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState(null);
    const [registeredUsername, setRegisteredUsername] = useState(null);

    const { userData } = useSelector(state => state.agenda);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userTeams = userData?.teams.reduce((acc, team) => ({
        ...acc,
        [team._id]: team
    }), {});

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(t('error_message.required_field')).matches(
            REGEX.NAME,
            { message: t('error_message.first_name_not_valid') }
        ),
        lastName: Yup.string().required(t('error_message.required_field')).matches(
            REGEX.NAME,
            { message: t('error_message.last_name_not_valid') }
        ),
        username: Yup.string().required(t('error_message.required_field')),
        email: Yup.string().matches(REGEX.EMAIL, { message: t('error_message.email_not_valid'), excludeEmptyString: true }),
        team: Yup.string().required(t('error_message.required_field'))
    });
    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors, isSubmitSuccessful },
        reset,
        setError
    } = useForm({ resolver: yupResolver(validationSchema) });

    useEffect(() => {
        if (isSubmitSuccessful && !getValues('email')) {
            reset();
        }
    }, [isSubmitSuccessful]);

    const handleApiError = (message) => {
        if (message === 'Username already taken') {
            setError('username', { type: 'manual', message: t('error_message.administration.username_taken') });
        } else if (message === 'Email already taken') {
            setError('email', { type: 'manual', message: t('error_message.administration.email_taken') });
        } else {
            dispatch(showToastErrorMessage(t('error_message.administration.failed_registering_user')));
        }
    };

    const handleRegisterUser = async () => {
        const firstName = getValues('firstName');
        const lastName = getValues('lastName');
        const username = getValues('username');
        const email = getValues('email');
        const teamFromValues = getValues('team');
        try {
            setIsLoading(true);
            const response = await axios.post('user/registerUser', {
                registrationData: {
                    firstName,
                    lastName,
                    username,
                    email,
                    team: teamFromValues,
                    company: userTeams[teamFromValues].company
                }
            });

            setRegisteredUsername(username);
            setGeneratedPassword(response.data.password);
            onUserAdded();
        } catch (error) {
            handleApiError(error.response.data.error);
        } finally {
            setIsLoading(false);
        }
    };

    const isUserOrTeamAdmin = userData.memberships.some((membership) => ['teamAdmin', 'user'].includes(membership?.role?.name));

    const availableTeams = () => {
        const teams = isUserOrTeamAdmin ?
            userData.memberships.filter((membership) => membership.role?.name === 'teamAdmin') :
            userData.teams;

        return teams?.map((team) => (
            <option key={team?._id} value={team?._id}>
                {team?.name}
            </option>
        ));
    };
    const copyToClipBoard = (text) => {
        dispatch(showToastSuccessMessage(t('content.copied_to_clipboard')));
        navigator.clipboard.writeText(text);
    };

    return (
        <Modal className="inviteUsers" show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">
                    <span>{t('administration.register_user')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(handleRegisterUser)} className="container d-flex flex-column">
                    <Form.Group className="mb-3">
                        <Form.Label>{t('sign_up.first_name')}</Form.Label>
                        <Form.Control
                            name="firstName"
                            {...register('firstName')}
                            error={errors.firstName?.message}
                            className="shadow-none border-color-primary"
                        />
                        {errors.firstName?.message && <Form.Label className="error-placeholder">{errors.firstName?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('sign_up.last_name')}</Form.Label>
                        <Form.Control
                            name="lastName"
                            {...register('lastName')}
                            error={errors.lastName?.message}
                            className="shadow-none border-color-primary"
                        />
                        {errors.lastName?.message && <Form.Label className="error-placeholder">{errors.lastName?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('administration.enter_username')}</Form.Label>
                        <Form.Control
                            name="username"
                            {...register('username')}
                            error={errors.username?.message}
                            className="shadow-none border-color-primary"
                        />
                        {errors.username?.message && <Form.Label className="error-placeholder">{errors.username?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('administration.enter_email_optional')}</Form.Label>
                        <Form.Control
                            name="email"
                            {...register('email')}
                            error={errors.email?.message}
                            className="shadow-none border-color-primary"
                        />
                        {errors.email?.message && <Form.Label className="error-placeholder">{errors.email?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-0">{t('administration.users_table.team')}</Form.Label>
                        <Form.Select
                            className="form-control mb-3 shadow-none border-color-primary"
                            isInvalid={errors?.team}
                            placeholder={t('administration.teams_table.companyName')}
                            name="team"
                            defaultValue={teamToRegisterTo ?
                                userTeams[teamToRegisterTo._id]._id :
                                userTeams[userData.lastSelectedTeam._id]._id
                            }
                            {...register('team')}
                        >
                            <option key="000" value="">{t('best_contribution.please_select')}</option>
                            {availableTeams()}
                        </Form.Select>
                        {errors?.team && <Form.Label className="error-placeholder">{errors.team?.message}</Form.Label>}
                    </Form.Group>
                    {generatedPassword && (
                        <div className="alert alert-success text-center">
                            {t('success_message.administration.account_created_for_user')}
                            :
                            {' '}
                            <strong>
                                {registeredUsername}
                            </strong>
                            <FontAwesomeIcon
                                icon={faCopy}
                                className="icon-gray align-middle ms-2"
                                onClick={() => copyToClipBoard(registeredUsername)}
                            />
                            {' '}
                            <br/>
                            {t('success_message.administration.generated_password')}
                            :
                            {' '}
                            <strong>{generatedPassword}</strong>
                            <FontAwesomeIcon
                                icon={faCopy}
                                className="icon-gray align-middle ms-2"
                                onClick={() => copyToClipBoard(generatedPassword)}
                            />
                        </div>
                    )}
                    <div className="d-flex justify-content-between">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal} disabled={isLoading}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button type="submit" className="primary-button" disabled={isLoading} style={{ width: '65%' }}>
                            {isLoading && <div className="spinner-border spinner-border-sm me-1" role="status"/>}
                            {t('administration.register_user')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default RegisterUsers;
