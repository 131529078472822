import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { COLORS } from '../../../../constants';

function AddBestContributionToGoalsAndMetrics({ closeModal, saveBestContribution, validationSchema, bestContributionsInModuleIds }) {
    const { t } = useTranslation();
    const { teamBestContributions } = useSelector((state) => state.actions);
    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validationSchema) });

    return (
        <Modal className="inviteUsers" show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">
                    <span>{t('content.add_best_contribution_to_measurements')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(saveBestContribution)}
                    className="container d-flex flex-column"
                >
                    <Form.Group className="mb-3">
                        <Form.Label>{t('best_contribution.best_contribution')}</Form.Label>
                        <Form.Select
                            className="removed-shadow mb-1"
                            isInvalid={errors.bestContribution?.message}
                            placeholder={t('best_contribution.best_contribution')}
                            name="bestContribution"
                            defaultValue=""
                            {...register('bestContribution')}
                        >
                            <option key="000" value="" disabled>{t('best_contribution.please_select')}</option>
                            {teamBestContributions?.map(bestContribution => !bestContributionsInModuleIds?.includes(bestContribution._id) &&
                            (<option value={bestContribution._id} key={bestContribution._id}>{bestContribution.title}</option>))}
                        </Form.Select>
                        {errors.bestContribution?.message &&
                            <Form.Label className="error-placeholder mb-2">{errors.bestContribution?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('content.end_goal')}</Form.Label>
                        <Form.Control
                            className="removed-shadow"
                            placeholder={t('content.end_goal')}
                            name="endGoal"
                            type="number"
                            {...register('endGoal')}
                            isInvalid={errors.endGoal?.message}
                        />
                        {errors.endGoal?.message &&
                            <Form.Label className="error-placeholder mb-2">{errors.endGoal?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-2">{t('content.select_graph_color')}</Form.Label>
                        <Form.Control
                            className="removed-shadow"
                            type="color"
                            name="color"
                            defaultValue={COLORS.PRIMARY_COLOR}
                            {...register('color', { value: COLORS.PRIMARY_COLOR })}
                        />
                        {errors.color?.message && <Form.Label className="error-placeholder">{errors.color?.message}</Form.Label>}
                    </Form.Group>
                    <div className="d-flex mt-4 justify-content-between">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button type="submit" className="primary-button sign-button" style={{ width: '65%' }}>
                            {t('administration.save')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AddBestContributionToGoalsAndMetrics;