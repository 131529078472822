import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import {
    editTaskToggle,
    fetchBestContributions,
    fetchUsers
} from '../../../../features/actionsSlice';

function EditTask({ save, moduleId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        showEditTask,
        editIdeas,
        task,
        users,
        createAction,
        moduleBestContributions,
        teamBestContributions
    } = useSelector(state => state.actions);
    const teamId = useSelector((state) => state.agenda?.userData?.lastSelectedTeam?._id);
    const [bestContributionsById, setBestContributionsById] = useState();
    const [areasOfImprovement, setAreasOfImprovement] = useState([]);
    const [errorMessages, setErrorMessages] = useState({
        title: false,
        bestContribution: false
    });
    const [isIdea, setIsIdea] = useState(false);
    const [taskData, setTaskData] = useState({ isIdea });

    useEffect(() => {
        (async () => {
            await dispatch(fetchUsers(teamId));
            await dispatch(fetchBestContributions({ moduleId, teamId }));
        })();
    }, [teamId, moduleId]);

    const reformatBestContributionsByIds = (bestContributions) => bestContributions?.reduce((acc, bestContribution) => ({
        ...acc,
        [bestContribution._id]: bestContribution
    }), []);

    useEffect(() => {
        if (moduleId && moduleBestContributions) {
            setBestContributionsById(reformatBestContributionsByIds(moduleBestContributions));
        } else if (!moduleId && teamBestContributions) {
            setBestContributionsById(reformatBestContributionsByIds(teamBestContributions));
        }
    }, [teamBestContributions, moduleBestContributions]);

    useEffect(() => {
        if (editIdeas) {
            setIsIdea(true);
        }
    }, [editIdeas]);

    const loadAreasOfImprovement = (bestContributionId) => {
        if (bestContributionId) {
            setAreasOfImprovement(bestContributionsById[bestContributionId]?.areasOfImprovement);
        } else {
            setAreasOfImprovement([]);
        }
    };

    useEffect(() => {
        if (bestContributionsById) {
            loadAreasOfImprovement(task?.bestContribution);
            setTaskData({
                ...taskData,
                title: task?.title,
                description: task?.description,
                responsible: task?.responsible,
                supportBy: task?.supportBy || (task?.fullTeam ? 'fullteam' : undefined),
                deadline: task?.deadline,
                status: task?.status,
                bestContribution: task?.bestContribution,
                areaOfImprovement: task?.areaOfImprovement,
                comment: task?.comment
            });
        }
    }, [task, bestContributionsById]);

    const handleField = (value, field) => {
        const updatedTaskData = { ...taskData };
        updatedTaskData[field] = value || null;
        setTaskData(updatedTaskData);
    };

    const handleTitle = (value) => {
        if (value) {
            setErrorMessages({ ...errorMessages, title: false });
        }

        const updatedTaskData = { ...taskData };
        updatedTaskData.title = value || null;
        setTaskData(updatedTaskData);
    };

    const handleSupportBy = (value) => {
        const updatedTaskData = { ...taskData };

        if (value === 'fullteam') {
            updatedTaskData.fullTeam = true;
            updatedTaskData.supportBy = null;
        } else if (value) {
            updatedTaskData.fullTeam = false;
            updatedTaskData.supportBy = value;
        } else {
            updatedTaskData.fullTeam = false;
            updatedTaskData.supportBy = null;
        }

        setTaskData(updatedTaskData);
    };

    const handleDate = (date) => {
        setTaskData({
            ...taskData,
            deadline: date
        });
    };

    const handleBestContribution = (bestContribution) => {
        const updatedTaskData = { ...taskData };
        updatedTaskData.areaOfImprovement = '';

        if (bestContribution) {
            updatedTaskData.bestContribution = bestContribution;
        } else {
            delete updatedTaskData.bestContribution;
        }

        loadAreasOfImprovement(bestContribution);
        setTaskData(updatedTaskData);
    };

    const close = () => {
        setErrorMessages({
            title: false,
            bestContribution: false
        });
        setIsIdea(false);
        dispatch(editTaskToggle({ isOpened: false, createAction: false }));
    };

    const saveTask = async () => {
        const updatedTaskData = { ...taskData, areaOfImprovement: taskData.areaOfImprovement || null, isIdea };

        if (!updatedTaskData.title) {
            setErrorMessages({ ...errorMessages, title: true });
            return;
        }

        if (!isIdea && !updatedTaskData.bestContribution) {
            setErrorMessages({ ...errorMessages, bestContribution: true });
            return;
        }

        if (updatedTaskData.supportBy === 'fullteam') {
            updatedTaskData.supportBy = null;
            updatedTaskData.fullTeam = true;
        }
        if (!isIdea) {
            updatedTaskData.module = bestContributionsById[updatedTaskData.bestContribution].module;
            updatedTaskData.status = updatedTaskData?.status || 0;
        }
        save(updatedTaskData, isIdea);
        setIsIdea(false);
        dispatch(editTaskToggle({ isOpened: false, createAction: false }));
        await dispatch(fetchBestContributions({ moduleId, teamId }));
    };

    return (
        <div className={`edit-task-form ps-5 ${(showEditTask) && 'show'}`}>
            <h3>{t(createAction ? 'content.create_new_action' : 'content.edit_action_task')}</h3>
            <Form className="col-md-12">
                {editIdeas &&
                    <div key="inline-radio" className="mt-4 mb-2">
                        <Form.Check
                            className="idea-form-check"
                            inline
                            label={t('content.this_will_stay_an_idea')}
                            name="group1"
                            type="radio"
                            defaultChecked={editIdeas}
                            id="inline-radio-1"
                            onClick={() => setIsIdea(true)}
                        />
                        <Form.Check
                            className="idea-form-check"
                            inline
                            label={t('content.task')}
                            name="group1"
                            type="radio"
                            id="inline-radio-2"
                            onClick={() => setIsIdea(false)}
                        />
                    </div>
                }
                <Form.Group className="mb-1">
                    <Form.Label className="mb-0">{t('content.title')}</Form.Label>
                    <Form.Control
                        className="form-control mb-3 border-color-primary shadow-none"
                        placeholder={t('content.title')}
                        name="title"
                        value={taskData?.title || ''}
                        onChange={(event) => handleTitle(event.target.value, 'title')}
                    />
                    {errorMessages?.title &&
                        <div className="errorMessage">
                            {t('error_message.edit_task.title_field_error')}
                        </div>
                    }
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Label className="mb-0">{t('content.description')}</Form.Label>
                    <Form.Control
                        as="textarea"
                        className="form-control mb-3 textbox border-color-primary shadow-none"
                        placeholder={t('content.description')}
                        name="description"
                        value={taskData?.description || ''}
                        onChange={(event) => handleField(event.target.value, 'description')}
                    />
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Label className="mb-0">{t('content.responsible')}</Form.Label>
                    <Form.Select
                        className="form-control mb-3 border-color-primary shadow-none"
                        placeholder={t('content.responsible')}
                        name="responsible"
                        value={taskData?.responsible || ''}
                        onChange={(event) => handleField(event.target.value, 'responsible')}
                        disabled={editIdeas && isIdea}
                    >
                        <option key="000" value="" disabled>{t('best_contribution.please_select')}</option>
                        {users?.map(user => user?.isActive && (
                            <option value={user._id} key={user._id}>{`${user.firstName} ${user.lastName}`}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Label className="mb-0">{t('content.support_by')}</Form.Label>
                    <Form.Select
                        className="form-control mb-3 border-color-primary shadow-none"
                        placeholder={t('content.support_by')}
                        name="support-by"
                        value={taskData?.supportBy || (taskData?.fullTeam ? 'fullteam' : '')}
                        onChange={(event) => handleSupportBy(event.target.value)}
                        disabled={editIdeas && isIdea}
                    >
                        <option key="000" value="" disabled>{t('best_contribution.please_select')}</option>
                        <option key="002" value="fullteam">{t('best_contribution.full_team')}</option>
                        {users?.map(user => user?.isActive && (
                            <option value={user._id} key={user._id}>{`${user.firstName} ${user.lastName}`}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Label className="mb-0">{t('content.deadline')}</Form.Label>
                    <DatePicker
                        className="datepicker w-100 border-color-primary shadow-none"
                        placeholderText={t('content.deadline_placeholder')}
                        selected={taskData?.deadline ? (new Date(taskData.deadline)).getTime() : undefined}
                        onChange={(date) => handleDate(date)}
                        dateFormat="dd.MM.yyyy"
                        disabled={editIdeas && isIdea}
                    />
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Label className="mb-0">{t('content.status')}</Form.Label>
                    <Form.Select
                        className="form-control mb-3 border-color-primary shadow-none"
                        placeholder={t('content.status')}
                        name="status"
                        value={taskData?.status || 0}
                        onChange={(event) => handleField(event.target.value, 'status')}
                        disabled={editIdeas && isIdea}
                    >
                        <option value="0" key="0">{t('best_contribution.task_statuses.not-started')}</option>
                        <option value="1" key="1">{t('best_contribution.task_statuses.in-progress')}</option>
                        <option value="2" key="2">{t('best_contribution.task_statuses.delayed')}</option>
                        <option value="3" key="3">{t('best_contribution.task_statuses.done')}</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Label className="mb-0">{t('best_contribution.best_contribution')}</Form.Label>
                    <Form.Select
                        className="form-control mb-3 border-color-primary shadow-none"
                        placeholder="Best Contribution"
                        name="best-contribution"
                        value={taskData?.bestContribution || ''}
                        onChange={(event) => handleBestContribution(event.target.value)}
                        disabled={editIdeas && isIdea}
                    >
                        <option key="000" value="" disabled>{t('best_contribution.please_select')}</option>
                        {bestContributionsById && Object.values(bestContributionsById)?.map((bestContribution) => (
                            <option value={bestContribution._id} key={bestContribution._id}>{bestContribution.title}</option>
                        ))}
                    </Form.Select>
                    {(errorMessages?.bestContribution && !isIdea) &&
                        <div className="errorMessage">
                            {t('error_message.edit_task.best_contribution_field_error')}
                        </div>
                    }
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Label className="mb-0">{t('content.area_of_improvement')}</Form.Label>
                    <Form.Select
                        className="form-control mb-3 border-color-primary shadow-none"
                        placeholder={t('content.area_of_improvement')}
                        name="area-of-improvement"
                        disabled={!areasOfImprovement?.length || (editIdeas && isIdea)}
                        value={taskData?.areaOfImprovement || ''}
                        onChange={(event) => handleField(event.target.value, 'areaOfImprovement')}
                    >
                        <option key="000" value="">{t('content.no_area_of_improvement')}</option>
                        {areasOfImprovement?.map((areaOfImprovement) => (
                            <option value={areaOfImprovement._id} key={areaOfImprovement._id}>{areaOfImprovement.title}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Label className="mb-0">{t('content.comment')}</Form.Label>
                    <Form.Control
                        as="textarea"
                        className="form-control mb-3 textbox border-color-primary shadow-none"
                        placeholder={t('content.comment')}
                        name="comment"
                        value={taskData?.comment || ''}
                        onChange={(event) => handleField(event.target.value, 'comment')}
                        disabled={editIdeas && isIdea}
                    />
                </Form.Group>
                <div className="d-flex justify-content-center mt-2">
                    <button
                        type="button"
                        className="primary-button w-25 me-4 button"
                        onClick={close}
                    >
                        {t('dashboard_shared.close')}
                    </button>
                    <button
                        type="button"
                        className="primary-button w-75 ms-1 button save"
                        onClick={saveTask}
                    >
                        {t(createAction ? 'dashboard_shared.create' : 'content.save')}
                    </button>
                </div>
            </Form>
        </div>
    );
}

export default EditTask;
