import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { axios } from '../../../helpers/apiHelper';
import { showToastErrorMessage, showToastSuccessMessage } from '../../../features/toastSlice';

function AddUsersByUsername({ closeModal, teamToAddTo, onUserAdded }) {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { userData } = useSelector((state) => state.agenda);
    const dispatch = useDispatch();

    const userTeams = userData?.teams.reduce((acc, team) => ({ ...acc, [team._id]: team }), {});

    const validationSchema = Yup.object().shape({
        username: Yup.string().required(t('error_message.required_field')),
        team: Yup.string().required(t('error_message.required_field'))
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validationSchema) });

    const selectedUser = watch('username');
    const selectedTeam = watch('team');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const fetchedUsersFromLoggedUserTeam = await axios.get('/user/withUsernamesOnly');
                setUsers(fetchedUsersFromLoggedUserTeam.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        if (teamToAddTo?._id) {
            setValue('team', teamToAddTo._id);
        }
        if (users.length > 0) {
            setValue('username', users[0]._id);
        }
    }, [teamToAddTo, users, setValue]);

    const handleApiError = (message) => {
        if (message === 'User is already a member of this team') {
            dispatch(showToastErrorMessage(t('error_message.administration.user_already_member_of_team')));
        } else {
            dispatch(showToastErrorMessage(t('error_message.administration.failed_adding_user_to_team')));
        }
    };

    const handleAddUser = async (data) => {
        setIsLoading(true);
        try {
            await axios.post('/user/addToTeam', {
                registrationData: {
                    userId: data.username,
                    team: data.team,
                    company: userTeams[data.team].company
                }
            });

            dispatch(showToastSuccessMessage(t('success_message.administration.user_successfully_added_to_team')));
            onUserAdded();
            closeModal();
        } catch (error) {
            console.error('Error adding user to team:', error);
            handleApiError(error.response.data.error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show centered size="md" onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('administration.add_user_by_username')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(handleAddUser)}>
                    {/* Select User */}
                    <Form.Group className="mb-3">
                        <Form.Label>{t('best_contribution.please_select')}</Form.Label>
                        <Form.Select {...register('username')} isInvalid={!!errors.username}>
                            <option value="" disabled>{t('best_contribution.please_select')}</option>
                            {users.map((user) => (
                                <option key={user._id} value={user._id}>
                                    {user.username}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.username && <Form.Label className="error-placeholder">{errors.username?.message}</Form.Label>}
                    </Form.Group>

                    {/* Select Team */}
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-0">{t('administration.users_table.team')}</Form.Label>
                        <Form.Select
                            className="form-control mb-3 shadow-none border-color-primary"
                            {...register('team')}
                            isInvalid={!!errors.team}
                            defaultValue={teamToAddTo?._id || userData?.lastSelectedTeam?._id || ''}
                        >
                            <option value="" disabled>{t('best_contribution.please_select')}</option>
                            {Object.values(userTeams || {}).map((team) => (
                                <option key={team._id} value={team._id}>
                                    {team.name}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.team && <Form.Label className="error-placeholder">{errors.team?.message}</Form.Label>}
                    </Form.Group>

                    {/* Submit Button */}
                    <div className="d-flex justify-content-between">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button
                            type="submit"
                            className="primary-button"
                            style={{ width: '65%' }}
                            disabled={isLoading || !selectedUser || !selectedTeam}
                        >
                            {isLoading && <div className="spinner-border spinner-border-sm me-1" role="status"/>}
                            {t('administration.add_user')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AddUsersByUsername;
