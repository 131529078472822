import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { ROLES } from '../../../constants';

function RoleDisplay({ roleName }) {
    const { t } = useTranslation();

    const roleEntry = Object.values(ROLES).find(role => role.name === roleName);

    const translatedRole = roleEntry ? t(`role.${roleEntry.key}`) : t(roleName);

    return translatedRole;
}

function UserDetailsModal({ closeModal, isOpen, userToListDetailsFor }) {
    const { t } = useTranslation();

    return (
        <Modal show={isOpen} onHide={closeModal} centered size="lg">
            <Modal.Header className="d-flex justify-content-center" closeButton>
                <Modal.Title>
                    {`${t('administration.user_details_for')} ${userToListDetailsFor?.name}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center modal-body-scroll">
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border px-4 py-2">{t('administration.users_table.team')}</th>
                            <th className="border px-4 py-2">{t('role.role')}</th>
                            <th className="border px-4 py-2">{t('administration.teams_table.companyName')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userToListDetailsFor?.memberships?.map((membership) => (
                            <tr key={membership._id} className="border-t">
                                <td className="border px-4 py-2">{membership.team.name}</td>
                                <td className="border px-4 py-2">
                                    <span><RoleDisplay roleName={membership.role.name}/></span>
                                </td>
                                <td className="border px-4 py-2">{membership.team.company.name}</td>
                            </tr>
                        )) || (
                            <tr>
                                <td className="border px-4 py-2" colSpan="2">
                                    {t('administration.no_memberships_found')}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                    {t('dashboard_shared.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default UserDetailsModal;