import { Modal, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { axios } from '../../../helpers/apiHelper';
import { showToastErrorMessage } from '../../../features/toastSlice';

function ListTeams({ closeModal, isOpen, companyToListTeamsFor }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!isOpen || !companyToListTeamsFor?._id) {
            return;
        }

        const fetchTeams = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`team/byCompany/${companyToListTeamsFor._id}`);
                setTeams(response.data.teams);
            } catch (err) {
                setError(t('error_message.loading_failed'));
                dispatch(showToastErrorMessage(t('error_message.loading_failed')));
            } finally {
                setLoading(false);
            }
        };

        fetchTeams();
    }, [companyToListTeamsFor, isOpen, dispatch, t]);

    return (
        <Modal show={isOpen} onHide={closeModal} centered size="md">
            <Modal.Header className="d-flex justify-content-center" closeButton>
                <Modal.Title>
                    {`${t('administration.list_teams_in')} ${companyToListTeamsFor?.name}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center modal-body-scroll">
                {loading && <p className="text-gray-500">{t('dashboard_shared.loading')}</p>}
                {error && <p className="text-red-500">{error}</p>}
                {!loading && !error && teams.length > 0 ? (
                    <ul className="list-disc pl-6 w-full">
                        {teams.map((team) => (
                            <li key={team._id} className="py-2">
                                {team.name}
                            </li>
                        ))}
                    </ul>
                ) : (!loading && !error && <p className="text-gray-500">{t('administration.no_teams_found')}</p>)}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                    {t('dashboard_shared.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ListTeams;