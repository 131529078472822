import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROLES_PERMISSIONS } from '../../../constants';
import { handleSwitchingLastSelectedTeam, logout } from '../../../features/agendaSlice';
import { axios } from '../../../helpers/apiHelper';
import i18n from '../../../i18n';
import PermissionWrapper from '../../permissionWrapper';
import { AccountDefaultImage } from '../../../assets/images';
import { showToastErrorMessage } from '../../../features/toastSlice';

function UserDropdown() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { userData } = useSelector((state) => state.agenda);
    const [openMenu, setOpenMenu] = useState(null);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        window.dispatchEvent(new Event('storage'));
    };

    const switchTeam = async (teamId) => {
        try {
            await axios.put('user/switchTeam', { teamId });
            await dispatch(handleSwitchingLastSelectedTeam());
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.global_message')));
        }
    };

    return (
        <Dropdown className="user-dropdown">
            <Dropdown.Toggle
                variant="success"
                className="toggle user-button me-3 justify-content-end"
                id="dropdown-basic"
            >
                <div className="user-info text-end d-none d-md-block">
                    <h5 className="mb-0">
                        {userData?.firstName || userData?.lastName ?
                            `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`.trim() :
                            userData?.username}
                    </h5>
                    <span className="user-team">{userData?.lastSelectedTeam?.name}</span>
                </div>
                <img src={userData?.lastSelectedTeam?.company?.avatar ?? AccountDefaultImage} alt="avatar" className="avatar"/>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown-menu dropdown-menu-end" style={{ zIndex: 9999 }}>
                <Dropdown.Item className="no-hover pl-3">
                    {userData?.firstName || userData?.lastName ?
                        `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`.trim() :
                        userData?.username}
                </Dropdown.Item>
                {userData?.teams?.length > 1 || (userData?.teams?.length > 0 && !userData?.lastSelectedTeam) ?
                    <Dropdown
                        className="custom-dropdown"
                        drop="start"
                        show={openMenu === 'team'}
                        onMouseEnter={() => {
                            setOpenMenu('team');
                        }}
                        onMouseLeave={() => {
                            setOpenMenu(null);
                        }}
                    >
                        <Dropdown.Toggle
                            className="toggle dropdown-menu-toggle team-toggle"
                            as="div"
                        >
                            {userData?.lastSelectedTeam?.name ?
                                `${t('header.switch_team')}${' ('}${userData?.lastSelectedTeam?.name}${')'}` :
                                t('dashboard_shared.choose_team')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="team-dropdown" onClick={(e) => { e.stopPropagation(); }}>
                            {/* Group teams by company */}
                            {Object.entries(userData?.teams.reduce((acc, team) => {
                                const companyId = team?.company?._id;
                                if (!acc[companyId]) {
                                    acc[companyId] = [];
                                }
                                acc[companyId].push(team);
                                return acc;
                            }, {})).map(([companyId, teams]) => {
                                const companyName = teams[0]?.company?.name;

                                return (
                                    <React.Fragment key={companyId}>
                                        {/* Display company name if it's not the only company */}
                                        {userData?.teams.reduce((acc, team) => {
                                            if (!acc.includes(team.company?._id)) { acc.push(team.company?._id); }
                                            return acc;
                                        }, []).length > 1 && (
                                            <Dropdown.Item
                                                className="company-item"
                                                as="button"
                                                disabled
                                            >
                                                {companyName}
                                            </Dropdown.Item>
                                        )}

                                        {/* Display the teams under the company */}
                                        {teams.map((team) => (
                                            <Dropdown.Item
                                                className={`dropdown-item ${team?._id === userData?.lastSelectedTeam?._id ?
                                                    'disabled-item' : ''}`}
                                                key={team?._id}
                                                onClick={() => {
                                                    if (team._id !== userData?.lastSelectedTeam?._id) {
                                                        switchTeam(team._id);
                                                    }
                                                }}
                                                disabled={team?._id === userData?.lastSelectedTeam?._id}
                                            >
                                                {team.name}
                                            </Dropdown.Item>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        </Dropdown.Menu>

                    </Dropdown> :
                    <Dropdown.Item className="no-hover">
                        {userData?.lastSelectedTeam?.name || t('administration.no_teams_available')}
                    </Dropdown.Item>
                }
                <Dropdown.Divider/>
                <Dropdown
                    className="custom-dropdown"
                    drop="start"
                    show={openMenu === 'admin'}
                    onMouseEnter={() => {
                        setOpenMenu('admin');
                    }}
                    onMouseLeave={() => {
                        setOpenMenu(null);
                    }}
                >
                    <Dropdown.Toggle
                        as="div"
                        className="toggle dropdown-menu-toggle"
                    >
                        {t('administration.administration')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Dropdown.Item
                            className="dropdown-item"
                            as="button"
                            onClick={() => {
                                navigate('/administration/users');
                            }}
                        >
                            {t('header.users')}
                        </Dropdown.Item>
                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.TEAM_WRITE]}>
                            <Dropdown.Item
                                className="dropdown-item"
                                as="button"
                                onClick={() => {
                                    navigate('/administration/teams');
                                }}
                            >
                                {t('header.teams')}
                            </Dropdown.Item>
                        </PermissionWrapper>
                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.COMPANY_WRITE]}>
                            <Dropdown.Item
                                className="dropdown-item"
                                as="button"
                                onClick={() => {
                                    navigate('/administration/companies');
                                }}
                            >
                                {t('header.companies')}
                            </Dropdown.Item>
                        </PermissionWrapper>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                    className="custom-dropdown"
                    drop="start"
                    show={openMenu === 'language'}
                    onMouseEnter={() => {
                        setOpenMenu('language');
                    }}
                    onMouseLeave={() => {
                        setOpenMenu(null);
                    }}
                >
                    <Dropdown.Toggle
                        className="toggle dropdown-menu-toggle"
                        as="div"
                    >
                        {t('header.language')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Dropdown.Item
                            disabled={i18n.language === 'en'}
                            className="dropdown-item"
                            onClick={() => {
                                changeLanguage('en');
                            }}
                        >
                            {t('header.english')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            disabled={i18n.language === 'se'}
                            className="dropdown-item"
                            onClick={() => {
                                changeLanguage('se');
                            }}
                        >
                            {t('header.swedish')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown.Item className="dropdown-item py-0" href={`mailto:${process.env.REACT_APP_EMAIL_FOR_USERS_SUPPORT}`}>
                    {t('dashboard_shared.help')}
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item py-0" onClick={() => dispatch(logout())}>
                    {t('header.logout')}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default UserDropdown;
