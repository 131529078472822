import { Modal, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { axios } from '../../../helpers/apiHelper';
import { showToastErrorMessage } from '../../../features/toastSlice';

function ListUsers({ closeModal, isOpen, companyToListUsersFor }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!isOpen || !companyToListUsersFor) { return; }

        const fetchUsers = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`user/byCompany/${companyToListUsersFor._id}`);
                setUsers(response.data.users);
            } catch (err) {
                setError(t('error_message.loading_failed'));
                dispatch(showToastErrorMessage(t('error_message.loading_failed')));
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [companyToListUsersFor, isOpen, dispatch, t]);

    return (
        <Modal show={isOpen} onHide={closeModal} centered size="lg">
            <Modal.Header className="d-flex justify-content-center" closeButton>
                <Modal.Title>
                    {t('administration.list_users_in')}
                    {' '}
                    {companyToListUsersFor?.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center modal-body-scroll">
                {loading && <p className="text-gray-500">{t('dashboard_shared.loading')}</p>}
                {error && <p className="text-red-500">{error}</p>}
                {!loading && !error && users.length > 0 ? (
                    <table className="table-auto w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border px-4 py-2">{t('administration.users_table.username')}</th>
                                <th className="border px-4 py-2">{t('administration.users_table.email')}</th>
                                <th className="border px-4 py-2">{t('administration.users_table.name')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user._id} className="border-t">
                                    <td className="border px-4 py-2">{user.username}</td>
                                    <td className="border px-4 py-2">{user.email}</td>
                                    <td className="border px-4 py-2">
                                        {user.firstName}
                                        {' '}
                                        {user.lastName}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                ) : (!loading && !error && <p className="text-gray-500">{t('administration.no_users_found')}</p>)}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                    {t('dashboard_shared.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ListUsers;