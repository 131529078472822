import React from 'react';
import { Form } from 'react-bootstrap';

function SearchHeader({ searchQuery, setSearchQuery, placeholder }) {
    return (
        <div className="d-flex flex-column">
            <Form.Control
                type="text"
                placeholder={placeholder}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="mt-1"
            />
        </div>
    );
}

export default SearchHeader;
