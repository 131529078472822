import { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { showToastErrorMessage, showToastSuccessMessage } from '../../../features/toastSlice';
import { axios } from '../../../helpers/apiHelper';
import PermissionWrapper from '../../permissionWrapper';
import SortableTable from '../sortableTable';
import EditCompany from './editCompany';
import NewCompany from './newCompany';
import { ROLES, ROLES_PERMISSIONS } from '../../../constants';
import CustomModal from '../../dashboard/modalCustom';
import { fetchUser } from '../../../features/agendaSlice';
import NewTeam from '../teams/newTeam';
import ListUsers from '../users/listUsers';
import ListTeams from '../teams/listTeams';

function CompaniesAdministration() {
    const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
    const [showListUsersModal, setShowListUsersModal] = useState(false);
    const [showListTeamsModal, setShowListTeamsModal] = useState(false);
    const [companyToCreateTeamFor, setCompanyToCreateTeamFor] = useState();
    const [companyToListFor, setCompanyToListFor] = useState();
    const [showMoreInfoAboutCompanyModal, setShowMoreInfoAboutCompanyModal] = useState(false);
    const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
    const [showAreYouSureYouWantToDeleteCompany, setShowAreYouSureYouWantToDeleteCompany] = useState(false);
    const [usersCompanies, setUsersCompanies] = useState();
    const [companyToShowMoreInfoAbout, setCompanyToShowMoreInfoAbout] = useState();
    const [companyToEdit, setCompanyToEdit] = useState();
    const [companyToDelete, setCompanyToDelete] = useState();
    const [updateOfCompaniesMade, setUpdateOfCompaniesMade] = useState();
    const [companiesFetched, setCompaniesFetched] = useState(false);
    const { userData: loggedUser } = useSelector(state => state.agenda);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const openEditCompanyModal = (company) => {
        setShowEditCompanyModal(true);
        setCompanyToEdit(company);
    };

    const toggleMoreInfoAboutCompanyModal = () => {
        setShowMoreInfoAboutCompanyModal(prevState => !prevState);
    };

    const openMoreInfoAboutCompanyModal = (company) => {
        setCompanyToShowMoreInfoAbout(company);
        toggleMoreInfoAboutCompanyModal();
    };

    const toggleAreYouSureYouWantToDeleteCompanyModal = () => {
        setShowAreYouSureYouWantToDeleteCompany(prevState => !prevState);
    };

    const toggleCreateTeamModal = (company) => {
        setShowCreateTeamModal(prevState => !prevState);
        setCompanyToCreateTeamFor(company);
    };

    const toggleListUsersModal = (company) => {
        setShowListUsersModal(prevState => !prevState);
        setCompanyToListFor(company);
    };

    const toggleListTeamsModal = (company) => {
        setShowListTeamsModal(prevState => !prevState);
        setCompanyToListFor(company);
    };

    const deleteCompany = async () => {
        try {
            await axios.delete(`company/${companyToDelete?._id}`);
            setUpdateOfCompaniesMade(true);
            toggleAreYouSureYouWantToDeleteCompanyModal();
            dispatch(showToastSuccessMessage(t('success_message.administration.company_successfully_deleted')));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.administration.deleting_company_failed')));
        }
    };

    const openAreYouSureYouWantToDeleteCompanyModal = (company) => {
        toggleAreYouSureYouWantToDeleteCompanyModal();
        setCompanyToDelete(company);
    };

    const companiesTableColumns = [
        { key: 'name', sortable: true },
        // clickable columns can be disabled for some rows - disabledKey is used for that - row[column.disabledKey] returns true
        // if it's disabled and in that case the disabledMessage will be displayed in cases of icon fields
        {
            key: 'view_more_info',
            icon: 'bi bi-info-square',
            restrictedAccess: ROLES_PERMISSIONS.COMPANY_READ,
            onClick: openMoreInfoAboutCompanyModal
        },
        {
            key: 'edit',
            icon: 'bi bi-pencil-square',
            restrictedAccess: ROLES_PERMISSIONS.COMPANY_WRITE,
            onClick: openEditCompanyModal
        }
    ];

    const addTeamColumn = {
        key: 'add_team',
        icon: 'bi bi-plus-square',
        restrictedAccess: ROLES_PERMISSIONS.TEAM_WRITE,
        onClick: toggleCreateTeamModal
    };

    const listUsersColumn = {
        key: 'list_users',
        icon: 'bi bi-info-square',
        restrictedAccess: ROLES_PERMISSIONS.USER_READ,
        onClick: toggleListUsersModal
    };

    const listTeamsColumn = {
        key: 'list_teams',
        icon: 'bi bi-info-square',
        restrictedAccess: ROLES_PERMISSIONS.TEAM_READ,
        onClick: toggleListTeamsModal
    };

    const deleteCompanyColumn = {
        key: 'delete',
        icon: 'bi bi-trash',
        disabledKey: 'disabledDeleting',
        restrictedAccess: ROLES_PERMISSIONS.COMPANY_WRITE,
        onClick: openAreYouSureYouWantToDeleteCompanyModal,
        headerSuffix: (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip>
                        {t('administration.cant_delete_company_details')}
                    </Tooltip>
                }
            >
                <i className="bi bi-info-square ms-1"/>
            </OverlayTrigger>
        )
    };

    const fetchUsersCompanies = async () => {
        setCompaniesFetched(false);
        try {
            const allCompaniesUserIsAdministrating = await axios.get('/company');
            setUsersCompanies(allCompaniesUserIsAdministrating?.data?.map(company => (
                {
                    ...company,
                    disabledDeleting: company?.teams?.length > 0,
                    admins: company?.admins?.filter(admin => admin?.isActive)
                }
            )));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.administration.failed_users_companies_loading')));
        }
        setCompaniesFetched(true);
    };

    useEffect(() => {
        (async () => {
            await fetchUsersCompanies();
        })();
    }, []);

    useEffect(() => {
        if (updateOfCompaniesMade) {
            (async () => {
                await fetchUsersCompanies();
                setUpdateOfCompaniesMade(false);
            })();
        }
    }, [updateOfCompaniesMade]);

    const toggleCreateCompanyModal = () => {
        setShowCreateCompanyModal(prevState => !prevState);
    };

    const closeEditCompanyModal = () => {
        setShowEditCompanyModal(prevState => !prevState);
        setCompanyToEdit();
    };

    const saveUpdatedCompany = async (editedCompany) => {
        try {
            await axios.put(`/company/${companyToEdit?._id}`, { companyData: { ...editedCompany, logo: editedCompany?.logo || null } });
            setUpdateOfCompaniesMade(true);
            closeEditCompanyModal();
            if (companyToEdit._id === loggedUser?.lastSelectedTeam.company._id) {
                dispatch(fetchUser());
            }
            dispatch(showToastSuccessMessage(t('success_message.administration.successfully_updated_company')));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.administration.failed_updating_company')));
        }
    };

    return (
        <div className="scrollableWrapper h-100">
            <div className="usersAdministration m-5 h-100 w-75">
                <div className="mt-5">
                    <h1>{t('administration.companies_administration')}</h1>
                </div>
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.COMPANY_WRITE]}>
                    {loggedUser?.role?.name === ROLES.superAdmin.name && (
                        <Button variant="link" className="shadow-none my-3 p-0 link-to-invite fs-5" onClick={toggleCreateCompanyModal}>
                            <span className="pe-2">{t('administration.create_new_company')}</span>
                            <i className="bi bi-plus-square cursor align-middle"/>
                        </Button>
                    )}
                </PermissionWrapper>
                {showCreateCompanyModal &&
                    <NewCompany
                        closeModal={toggleCreateCompanyModal}
                        setUpdateOfCompaniesMade={setUpdateOfCompaniesMade}
                    />
                }
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.COMPANY_READ]}>
                    <div className="d-flex flex-column align-items-start my-4 pb-5">
                        <h3>{t('administration.companies_table_headline')}</h3>
                        {companiesFetched && usersCompanies?.length > 0 &&
                            <SortableTable
                                rows={usersCompanies}
                                columns={
                                    loggedUser?.role?.name === ROLES.superAdmin.name ?
                                        [...companiesTableColumns, deleteCompanyColumn, addTeamColumn, listUsersColumn, listTeamsColumn] :
                                        [...companiesTableColumns, addTeamColumn, listUsersColumn, listTeamsColumn]
                                }
                                translationPath="administration.teams_table"
                            />
                        }
                        {companiesFetched && usersCompanies?.length === 0 && <p>{t('administration.no_companies')}</p>}
                        {!companiesFetched && <Spinner animation="border"/>}
                    </div>
                </PermissionWrapper>
                {showEditCompanyModal &&
                    <EditCompany
                        closeModal={closeEditCompanyModal}
                        company={companyToEdit}
                        editCompany={saveUpdatedCompany}
                    />
                }
                {showAreYouSureYouWantToDeleteCompany &&
                    <CustomModal
                        title={`${t('administration.delete_company')} ${companyToDelete?.name}`}
                        text1={t('administration.are_you_sure_you_want_to_delete_company')}
                        warningText={t('administration.company_will_be_temporarily_deleted')}
                        secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                        primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                        onPrimaryButtonClick={deleteCompany}
                        onSecondaryButtonClick={toggleAreYouSureYouWantToDeleteCompanyModal}
                    />
                }
                {showMoreInfoAboutCompanyModal &&
                    <EditCompany
                        readOnly
                        closeModal={toggleMoreInfoAboutCompanyModal}
                        company={companyToShowMoreInfoAbout}
                    />
                }
                {showCreateTeamModal && (
                    <NewTeam
                        closeModal={toggleCreateTeamModal}
                        companies={usersCompanies}
                        companyToCreateTeamFor={companyToCreateTeamFor}
                    />
                )}
                {showListUsersModal && (
                    <ListUsers
                        closeModal={toggleListUsersModal}
                        isOpen={showListUsersModal}
                        companyToListUsersFor={companyToListFor}
                    />
                )}
                {showListTeamsModal && (
                    <ListTeams
                        closeModal={toggleListTeamsModal}
                        isOpen={showListTeamsModal}
                        companyToListTeamsFor={companyToListFor}
                    />
                )}
            </div>
        </div>
    );
}

export default CompaniesAdministration;