import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InviteUsers from '../users/inviteUsers';
import AddUsersByUsername from './addUsersByUsername';

function AddUsersToTeamRouter({ closeModal, teamToAddTo, onUserAdded }) {
    const [method, setMethod] = useState(null);
    const { t } = useTranslation();

    const handleMethodSelection = (newMethod) => {
        setMethod(newMethod);
    };

    return (
        <Modal show centered size="md" onHide={closeModal}>
            <Modal.Header closeButton className="justify-content-center">
                <Modal.Title>
                    {' '}
                    {t('administration.method_to_add_user')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!method && (
                    <div className="d-flex flex-column">
                        <Button
                            onClick={() => handleMethodSelection('email')}
                            className="primary-button mb-3 w-75 mx-auto"
                        >
                            {t('administration.invite_by_email')}
                        </Button>
                        <Button
                            onClick={() => handleMethodSelection('username')}
                            className="primary-button mb-3 w-75 mx-auto"
                        >
                            {t('administration.select_user_by_username')}
                        </Button>
                    </div>
                )}

                {method === 'email' && <InviteUsers closeModal={closeModal} teamToInviteTo={teamToAddTo}/>}
                {method === 'username' && <AddUsersByUsername closeModal={closeModal} teamToAddTo={teamToAddTo} onUserAdded={onUserAdded}/>}
            </Modal.Body>
        </Modal>
    );
}

export default AddUsersToTeamRouter;
