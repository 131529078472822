import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { axios } from '../helpers/apiHelper';
import { API_URL, REGEX } from '../constants';

function FirstLoginPasswordChange() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isValidPassword = (pw) => REGEX.PASSWORD.test(pw);

    const handlePasswordChange = async (event) => {
        event.preventDefault();

        if (!isValidPassword(password)) {
            setErrorMessage(t('error_message.password_format_message'));
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage(t('error_message.passwords_do_not_match'));
            return;
        }

        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem('user'));

        try {
            const updatedUser = await axios.put(
                `${API_URL}/user/updatePasswordFirstLogin`,
                {
                    userId: user._id,
                    password
                }
            );

            if (updatedUser.data) {
                localStorage.setItem('user', JSON.stringify(updatedUser?.data?.user));
                localStorage.setItem('x-access-token', updatedUser?.data?.token);
                navigate('/');
            }
        } catch (error) {
            setErrorMessage(t('error_message.failed_password_change'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
            <div className="container password-container border col col-xs-10 col-lg-6 m-auto p-5">
                <Button
                    variant="link"
                    className="link align-self-end shadow-none"
                    onClick={() => {
                        localStorage.removeItem('x-access-token');
                        navigate('/sign-in');
                    }
                    }
                >
                    {t('sign_up.go_back_to_sign_in')}
                </Button>
                <h2 className="align-self-start mt-2 mb-4">{t('sign_up.change_your_password')}</h2>
                <Form className="w-100" onSubmit={handlePasswordChange}>
                    <p>
                        {`${t('sign_up.change_your_password_description')} ${t('error_message.password_format_message')}`}
                    </p>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-0">{t('sign_up.new_password')}</Form.Label>
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder={t('sign_up.password')}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            isInvalid={!!errorMessage && !isValidPassword(password)}
                            className="shadow-none"
                        />
                        {errorMessage &&
                            <Form.Label className="error-placeholder">{errorMessage}</Form.Label>
                        }
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label className="mb-0">{t('sign_up.confirm_password')}</Form.Label>
                        <Form.Control
                            name="confirmPassword"
                            type="password"
                            placeholder={t('sign_up.confirm')}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            isInvalid={!!errorMessage && !isValidPassword(password)}
                            className="shadow-none"
                        />
                        {errorMessage &&
                            <Form.Label className="error-placeholder">{errorMessage}</Form.Label>
                        }
                    </Form.Group>
                    <Button disabled={isLoading} type="submit" className="primary-button w-100 py-2">
                        {isLoading ? t('sign_up.updating') : t('sign_up.save_new_password')}
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default FirstLoginPasswordChange;
